import React, { Fragment, useEffect, useState } from "react";
import { Button, Carousel } from "@material-tailwind/react";
import CustomSlider from "../../components/_customcarSlider/CustomSlider";
import CustomPictureSlider from "../../components/_customcarSlider/CustomPictureSlider";
import { _getNewProduct, _getSuggestProduct } from "../../utils/requestUtils";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import AnimatedSkeleton from "../../components/skeleton/SuggestSkeleton";
import { pictureSrcUpdate } from "../../utils/imgsrc-utils";
const Home = () => {
  const [newCarData, setNewCarData] = useState([]);
  const [suggestdata, setSuggestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const navigate = useNavigate();

  const SA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;
  //get new product bro :)
  useEffect(() => {
    try {
      _getNewProduct().then((data) => {
        setNewCarData(data)
        setLoading(false)
      });
    } catch (err) { }
  }, [loading]);

  //get suggest product bro :)
  useEffect(() => {
    try {
      _getSuggestProduct().then((data) => {
        setSuggestData(data)
        setLoading2(false)
      });
    } catch (err) { }
  }, [loading2]);




  return (
    <div className="bg-[#d1d5db] overflow-hidden  ">
      <Helmet>
        <title>Welcome to CheckedVin | Checkedvin.com</title>
        <meta name="title" content={`Welcome to CheckedVin | Checkedvin.com`} />
        <meta
          name="description"
          content="Explore new cars and find the perfect vehicle for you. CheckedVin offers a wide range of cars with detailed information for your needs."
        />
      </Helmet>
      <CustomPictureSlider />

      <div className='homeContent-Container p-4 w-full flex gap-[40px] flex-col  justify-center items-center'>
        <div className="w-[80vw] flex flex-col justify-center items-start text-wrap  ">
          <h1>VIN Codes, Car History, and Vehicle History Records</h1>
          Whether you're buying or selling a car, the CheckedVin website is your go-to resource for all important information and data on used vehicles. \
          Here's what you can find:
          <p> 1. Detailed History: View detailed records of past accidents, maintenance, repairs, and lot prices.</p>
          <p> 2. Damage Insights: View and download images showing damages on various parts of the car.</p>
          <p> 3. Vehicle Chronicle: Get an in-depth overview of the car of your interest to help you make informed buying decisions.</p>
          CheckedVin is the best tool for understanding a vehicle's history with a large database of used cars. You can use our website to compare different cars and assess their value accurately. Both for buyers and sellers (including past car owners), it helps determine the fair price by revealing how much information is available about a specific model or VIN code.
        </div>
      </div>

      <div className="homeContent-Container p-2 w-full flex gap-[40px] flex-col  justify-center items-center  ">
        <div className="new-Product  pt-4   ">
          <h1 className="p-2 m-2 text-xl text-bold">New Cars</h1>

          <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 place-content-center  gap-x-20 gap-y-15 w-[80vw]">
            {(loading && newCarData?.length === 0) && (
              Array.from({ length: 10 }).map((_, index) => (
                <div
                  key={index}
                  className="newCars-Container items-start  p-1 flex flex-col gap-2"
                >

                  <AnimatedSkeleton />
                </div>
              ))
            )}
            {(newCarData?.length !== 0 && !loading) && newCarData?.map((item, index) => (
              <div
                key={item?.id}
                className="newCars-Container items-start  p-1 flex flex-col gap-2"
              >
                {/* <CustomSlider item={item} /> */}
                <div className="rounded-[6px] sm:rounded-xl w-[130px] h-[100px] sm:w-[200px] sm:h-[150px] overflow-hidden relative">
                  <div
                    className="flex transition-all h-full duration-500"
                  >
                    <img
                      src={
                        pictureSrcUpdate(item)
                      }
                      alt={`${item.vin}_${item.name}_slider_1`}
                      className=""
                    />
                  </div>
                </div>
                <p className="whitespace-nowrap text-elipsis w-full overflow-hidden text-[14px]">{item?.name}</p>
                <p className="whitespace-nowrap">{item?.final_bid_formatted === "0" || !item?.final_bid_formatted || item.final_bid_formatted === "---" ? "$ 0.00" : item?.final_bid_formatted}</p>
                <Button onClick={() => window.open(`/product/vehicle/${item?.lot}/${item?.tag ? item?.tag?.replace(/ /g, '-') : item?.name?.replace(/ /g, '-')}`)}>View More</Button>
              </div>
            ))}
          </div>
        </div>

        <div className='homeContent-Container p-4 w-full flex gap-[40px] flex-col  justify-center items-center'>
          <div className="w-[80vw] flex flex-col justify-center items-start text-wrap  ">
            Knowing a car’s history can be a life-saver, especially for online purchases. 
            It highlights past issues, repairs, and safety concerns, helping you evaluate the car’s reliability. 
            CheckedVin also has access to some “hard-to-find” info, such as maintenance records and previous ownership, uncovering any hidden problems. 
            With this knowledge, you can negotiate a fair price or avoid problematic cars altogether.
            Watch out for red flags like inconsistent mileage, frequent repairs, or a history of harsh accidents.
             These can mean that a car has serious underlying issues and it may lead to costly repairs or safety hazards down the road. 
            Always examine the vehicle history report to ensure you're making a smart investment.
          </div>
        </div>
 
        <div className="new-Product  pt-4  ">
          <h1 className="p-2 m-2 text-xl text-bold">Suggest For You</h1>

          <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 place-content-center gap-x-20 gap-y-15 w-[80vw]    ">
        {(loading && newCarData?.length === 0) && (
              Array.from({ length: 10 }).map((_, index) => (
                <div
                  key={index}
                  className="newCars-Container items-start  p-1 flex flex-col gap-2"
                >

                  <AnimatedSkeleton />
                </div>
              ))
            )}

            {(loading2 && suggestdata?.length === 0) && (
              Array.from({ length: 10 }).map((_, index) => (
                <div
                  key={index}
                  className="newCars-Container items-start  p-1 flex flex-col gap-2"
                >

                  <AnimatedSkeleton />
                </div>
              ))
            )}
            {(suggestdata?.length !== 0 && !loading2) && suggestdata?.map((item, index) => (
              <div
                key={item?.id}
                className="newCars-Container items-start  p-1 flex flex-col gap-2"
              >
                {/* <CustomSlider item={item} /> */}
                <div className="rounded-[6px] sm:rounded-xl w-[130px] h-[100px] sm:w-[200px] sm:h-[150px] overflow-hidden relative">
                  <div
                    className="flex transition-all h-full duration-500"

                  >
                    <img

                      src={pictureSrcUpdate(item)}
                      alt={`${item.vin}_${item.name}_slider_1`}
                      className=""
                    />
                  </div>
                </div>
                <p className="whitespace-nowrap text-elipsis w-full overflow-hidden text-[14px]">{item?.name}</p>
                <p className="whitespace-nowrap">$ {item?.final_bid_formatted === "0" || item.final_bid_formatted === "---" || !item.final_bid_formatted ? "00.00" : item?.final_bid_formatted}</p>
                <Button onClick={() => window.open(`/product/vehicle/${item?.lot}/${item?.tag ? item?.tag?.replace(/ /g, '-') : item?.name?.replace(/ /g, '-')}`)}>View More</Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
