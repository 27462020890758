import { Carousel } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { pictureSrcForProduct } from "../../utils/imgsrc-utils";
const CustomSingleSlider = ({ item ,currentImage}) => {
  const PA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

 
   useEffect(() => {
    setCurrentImageIndex(currentImage);
   },[currentImage])


  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };
  return (
    <div className="rounded-[6px] sm:rounded-xl w-[350px] h-[310px] sm:w-[600px] sm:h-[500px] overflow-hidden relative">
      <div
        className="flex transition-all h-full  duration-500"
        style={{
          transform: `translateX(-${currentImageIndex * 100}%)`,
        }}
      >
        {item?.imgUrls && item?.imgUrls.map((image, index) => (
          <img
            key={index}
            src={pictureSrcForProduct(item,image)}
            alt={`${item.vin}_${item.name}_photo no.${index + 1}`}
            className=""
          />
        ))}
      </div>

      {/* Navigation dots */}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {item?.imgUrls && item?.imgUrls.map((_, index) => (
          <button
            key={index}
            onClick={() => goToImage(index)}
            className={`w-2 h-2 rounded-full bg-gray-300 ${
              index === currentImageIndex ? "bg-gray-600" : ""
            }`}
          />
        ))}
      </div>

      {/* Navigation arrows */}
      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white bg-[] p-2 rounded-full"
        onClick={() =>
          setCurrentImageIndex(
            (prevIndex) =>
              (prevIndex - 1 + item?.imgUrls?.length) %
              item?.imgUrls?.length
          )
        }
      >
        <AiOutlineArrowLeft />
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white bg-[] p-2 rounded-full"
        onClick={() =>
          setCurrentImageIndex(
            (prevIndex) => (prevIndex + 1) % item?.imgUrls?.length
          )
        }
      >
        <AiOutlineArrowRight />
      </button>
    </div>
  );
};

export default CustomSingleSlider;
