import React from 'react'

const Productskeleton = () => {
  return (
    <div className="p-10 animate-pulse md:w-[50%] ">

<h2 className="w-1/2 h-4 items-center bg-gray-600 m-2 "></h2>
<div className="md:flex w-full gap-[15%]">
<div className="rounded-[6px] sm:rounded-xl w-[350px] h-[310px] sm:w-[600px] sm:h-[500px] items-center bg-gray-600 m-2 overflow-hidden "/>
            <div className="grid  grid-cols-2 md:grid-cols-2 w-full h-[40%] md:w-[35%] md:h-[20%] md:gap-x-[50%]  gap-x-1 gap-y-[2px] pt-6">
         
                <p
              
                  className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px]  items-center bg-gray-600 m-2 border rounded-[8px]   "
              />
                 <p
              
              className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px]  items-center bg-gray-600 m-2 border rounded-[8px]   "
          />
             <p
              
              className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px]  items-center bg-gray-600 m-2 border rounded-[8px]  "
          />
             <p
              
              className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px] items-center bg-gray-600 m-2 border rounded-[8px]  "
          />
             <p
              
              className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px] items-center bg-gray-600 m-2 border rounded-[8px] "
          />
             <p
              
              className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px] items-center bg-gray-600 m-2 border rounded-[8px]  "
          />
             <p
              
              className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px] items-center bg-gray-600 m-2 border rounded-[8px]  "
          />
            
             </div>
           


         
            

          </div>
          {/* Skeleton loading for main content */}
          <div className="flex flex-col md:flex-row pt-4">
            <div className="sm:pt-4  m-auto p-4 md:rounded-l-xl shadow-lg w-full md:w-[60%] ">
              <h1 className="p-2  border-b border-[#fff]"></h1>

              {/* Add skeleton loading elements for details */}
              {/* Example: */}
              <div className="pt-2 p-3 m-1   gap-3 flex justify-between border-b border-[#0d1d25]">
                <span className=" h-4 w-24 items-center bg-gray-600"></span>
                <span className="flex items-center bg-gray-600 h-4 w-24 rounded"></span>
              </div>
              <div className="pt-2 p-3 m-1   flex justify-between  ">
                <span className="w-full h-4 items-center bg-gray-600"></span>
            </div>
            <div className="pt-2 p-3 m-1   flex justify-between  ">
                <span className="w-full h-4 items-center bg-gray-600"></span>
               
            </div>
            <div className="pt-2 p-3 m-1   flex justify-between  ">
                <span className="w-full h-4 items-center bg-gray-600"></span>
              
            </div>
            <div className="pt-2 p-3 m-1   flex justify-between  ">
                <span className="w-full h-4 items-center bg-gray-600"></span>
                
            </div>
            <div className="pt-2 p-3 m-1   flex justify-between  ">
                <span className="w-full h-4 items-center bg-gray-600"></span>
                
            </div>
            <div className="pt-2 p-3 m-1   flex justify-between  ">
                <span className="w-full h-4 items-center bg-gray-600"></span>
                
            </div>
              <div className="pt-2 p-3 m-1   flex justify-between  ">
                <span className="w-full h-4 items-center bg-gray-600"></span>
                
            </div>

              {/* Repeat similar structures for other details */}
              {/* ... */}
            </div>

            {/* Add skeleton loading elements for additional information */}
            <div className="bg-[#fffff] m-auto flex flex-col gap-y-5 rounded-b-md text-[10px] sm:text-[16px] w-[100%] h-[200px] md:w-[40%] md:h-[200px] p-5">
              <span className=" bg-gray-600 h-full w-full rounded"></span>

              {/* Add more skeleton loading elements */}
              {/* ... */}
            </div>
          </div>

          {/* Additional skeleton loading elements */}
          {/* ... */}


        
        
        </div>

  )
}

export default Productskeleton
