import React from 'react';
import { Link } from 'react-router-dom';
import { MdTranslate } from "react-icons/md";
const Terms = () => {
  return (
    <div className="container mx-auto p-8">
      <div className="mb-8">

        <h1 className="text-4xl font-bold mb-4 text-indigo-700">Terms & Conditions</h1>
        <h2>Explanation of Terms</h2>

        <span className="text-lg">
          <p>   CheckedVin - is a privately owned company that operates globally under the legal name CheckedVin Ltd. CheckedVin is the official name of the legal entity and has the website with the same name: checkedvincom.</p>
          <p>   Vehicle / Car - Motor vehicle with its unique VIN code, details, and vehicle information described on the CheckedVin website.</p>
          <p>   Terms and Conditions - Terms and conditions are outlined in the current document.</p>
          <p>   Website - Internet platform owned by CheckedVin at is managed by a specific set of rules outlined in the Terms and Conditions and is utilized for digital services.</p>
          <p>   Services - Services provided by CheckedVin at https://checkedvin.com, as stated in this document, are known as electronic services.</p>
          <p>   User - any tangible being utilizing the facilities of CheckedVin via the website and in compliance with the terms of the present document</p>
          <p>   Customer - any organization or a person utilizing paid services (like VIN removal services)</p>

        </span>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">GENERAL REGULATIONS</h2>
        <ul className="list-disc list-inside mb-4">
          The Terms and Conditions (this document) details the rights and responsibilities of users, customers, and website owners, available digitally on CheckedVin’s official website: https://checkedvin.com.
        </ul>
        <p>
          Before using the Website, the User must read and agree to the Terms and Conditions.
          The CheckedVin website, located at https://checkedvin.com, caters to all individuals interested in the services provided by Checkedvin.

        </p>
      </div>
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Electronic Services Provided on the Site
        </h2>
        <p>
          CheckedVin provides services on its official website that include:</p>
        <p> Accessing specific data and details about vehicles</p>
        <p> Obtaining the VIN code and visual data of the vehicles</p>
        <p> Giving Users the ability to ask for VIN information to be deleted.</p>
        <p> Providing Users with the ability to ask for updates to any information pertaining to particular Vehicles.</p>
        <p>
          There is no need for a separate agreement to perform the services mentioned by the Users or other parties involved.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Vehicle</h2>
        <p>
          CheckedVin does not guarantee the accuracy of vehicle information on the website,
          as it relies on official sources for data updates and verification,
          specifically disclaiming responsibility for any inaccuracies.
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Incorrect odometer reading for a particular vehicle</li>
          <li>  Vehicle's technical state.</li>
          <li> The present look of the vehicle</li>
          <li>Vehicle ownership details</li>
          <li>Vehicle pricing details</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Service Terms and Conditions</h2>
        <p>Services are available to any User who is interested and are provided at no cost (excluding the VIN removal service).
          Users must fulfill certain technical requirements on the CheckedVin website in order to access services.
        </p>
        <p>
          The user needs to possess an electronic device which enables:
          Internet accessibility
          Exploring various online sources
          Correspondence via email

        </p>
      </div>

    </div>
  );
};

export default Terms;
