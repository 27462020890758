import React from 'react'
const Contact = () => {
const PA = "https://allvinhistory-bucket.s3.amazonaws.com/assets/admin"


  return (
    <div className='overflow-hidden'>
       
        <div className=" h-[400px]   flex items-center justify-center overflow-hidden relative">
    
        <img
          src={PA + "/contact.jpg"}
          alt=" Contact picture"
          className={`h-full w-full object-cover`}
        />
  
   
          <div
        className={`absolute top-0 left-0 w-full h-full `}
        style={{ background: "rgba(0, 0, 0, 0.7)" }}
      ></div>
      <div
        className={
          "absolute left-[25%] top-[45%] md:left-[40%] md:top-[60%]  w-[350px] h-[400px] md:w-[400px] md:h-[450px]  text-white "
        }
      >
       
           <h1 className="text-5xl">Contact Us</h1> 
      
      </div>
    </div>
        
       


        <div className="contactInfo flex flex-col md:flex-row   p-0  gap-10 h-[400px] ">
      
            <div className='contactInfo-right mx-auto flex flex-col w-[60%] gap-4 pt-10 '>
            <h1 className="border-b-2 border-gray-600 p-3">Contact Info</h1>

             <div className="flex gap-2">
                <span>Email:</span>
                <span >checkedvin@gmail.com</span>
             </div>
             <div className="flex gap-2">
                <span >Address:</span>
                <span >11910 Parklawn Dr, North Bethesda, MD 20852</span>
             </div>
             <div className="flex gap-2">
                <span >Name:</span>
                <span >Heritage Park Business Center</span>
             </div>
          </div>
       
        </div>

        <div  className="google-map-left w-[400px]  ">
    
            </div> 
    </div>
  )
}

export default Contact