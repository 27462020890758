import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import "./main.css"
import { ThemeProvider } from '@material-tailwind/react';
import MyContextProvider from './context/Contextprovider';
ReactDOM.hydrateRoot(   document.querySelector('#root'),
<BrowserRouter>
   <ThemeProvider>
    <MyContextProvider>
    <App />
    </MyContextProvider>
    </ThemeProvider>
  </BrowserRouter>
 );


