import React from "react";
import { BiLogoGmail } from "react-icons/bi";
import { FaMailBulk, FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const PA = "https://vinhistory.s3.amazonaws.com/public/admin"
  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full md:px-24 lg:px-8 bg-[#0f5156] overflow-hidden ">
      <div className="grid gap-16 row-gap-5 md:row-gap-10 mb-8 lg:grid-cols-6">
        <div className="md:max-w-md lg:col-span-2">
          <Link to="/">
            <span className="inline-flex items-center">
              <img className="w-[100px] h-[100px]" src={PA + '/checkedvinweb1.png'} alt="checkedvin-logo" />
              <span className="ml-2 text-xl font-bold tracking-wide text-[#f7f7f7] uppercase">
                checkedvin
              </span>
            </span>
          </Link>
          <div className="mt-4 lg:max-w-sm">
            <p className="text-sm text-[#acabab] ">
              Welcome to CheckedVin(checkedvin.com), your premier destination for
              importing vehicles from the USA! With over four years of
              experience in the industry, we take pride in offering top-quality,
              reliable, and authentic imported vehicles to our valued customers.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2  gap-2  md:gap-5 row-gap-2 md:row-gap-8 lg:col-span-4 md:grid-cols-4">
          <div>
            <p className="font-semibold tracking-wide text-white">Menu</p>
            <ul className="mt-2 space-y-2 text-[#d1d1d1]">
              <li>About Us</li>
              <li>Contact</li>
            </ul>
          </div>
          <div className="text-white">
            <p className="font-semibold tracking-wide text-white ">Policy</p>
            <ul className="mt-2 space-y-2 ">
             <Link to="/check/terms">
              <li>Terms & Conditions</li>
              </Link>
              <Link to="/check/privacy">
              <li>Privacy Policy</li>
              </Link>
             
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-white">About Us</p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to='/about/business'>
                  <span

                    className="text-[#d1d1d1] transition-colors duration-300 hover:text-deep-purple-accent-400"
                  >
                    Business
                  </span>
                </Link>
              </li>

            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-white">Contact</p>
            <div className="mt-2 space-y-2 text-[#d1d1d1]">
              <span className="whitespace-nowrap flex items-center"><FaMailBulk size="20"/>checkedvininfo@gmail.com</span>

              <span className="flex gap-1 items-center whitespace-nowrap "> <FaTelegram size="25" />
                <a href="https://t.me/checkedvininfo" target="_blank" rel="noopener noreferrer">
                  @checkedvin.com
                </a>
                </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row">
        <p className="text-sm text-gray-600">© Copyright 2024 CHECKEDVIN.</p>
        <div className="flex gap-5 font-black text-gray-500">
          <h3>COPART</h3>
          <h3>IAAI</h3>
        </div>
        <div className="flex items-center mt-4 space-x-4 sm:mt-0">
          <a
            href="https://mail.google.com/mail/u/0/#inbox?compose=new"
            target="_blank"
            className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <BiLogoGmail style={{ width: "30px", height: "30px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
