// AnimatedSkeleton.js
import React from 'react';
import ContentLoader from 'react-content-loader';

const AnimatedSkeleton = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={400}
    viewBox="0 0 300 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />
    <rect x="0" y="220" rx="4" ry="4" width="100%" height="20" />
    <rect x="0" y="250" rx="3" ry="3" width="50%" height="20" />
    <rect x="0" y="290" rx="3" ry="3" width="80%" height="20" />
    <rect x="0" y="330" rx="3" ry="3" width="60%" height="20" />

    {/* Animated lines */}
    <rect x="0" y="360" rx="3" ry="3" width="25%" height="15" />
    <rect x="80" y="360" rx="3" ry="3" width="25%" height="15" />
    <rect x="160" y="360" rx="3" ry="3" width="25%" height="15" />
  </ContentLoader>
);

export default AnimatedSkeleton;