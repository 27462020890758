import { publicRequest } from "./requestMethods";

const requestUtils = {
  _getNewProduct: async () => {
    try {
      const newProductdata = await publicRequest.get("/public/random/product/check");
      return newProductdata.data;
    } catch (err) {
      return err
    }
  },
  _getSuggestProduct: async () => {
    try {
      const SuggestProductdata = await publicRequest.get("/public/random/product/check");
      return SuggestProductdata.data;
    } catch (err) {}
  },
  _getProductById: async (lot) => {
    try {
      const productById = await publicRequest.get("/public/product/check/" + lot);
      return productById.data;
    } catch (err) {
      return err;
    }
  },
  _getProductByVin: async (vin) => {
    try {
      const productByVin = await publicRequest.get("/public/get/check/" + vin);
      return productByVin.data;
    } catch (err) {
      return err;
    }
  },
  _getProductByQuery: async (query,vehiclePageCount) => {
    try {
      const productByQuery = await publicRequest.get(
        "/public/vehicle?searchVehicle=" +  query + "&page="+ vehiclePageCount
      );
      return productByQuery.data;
    } catch (err) {
      return err;
    }
  },
  _getProductsByUpdate: async (vehiclePageCount) => {
    try {
      const productByQuery = await publicRequest.get("/public/vehicle/checkedvin?page=" + vehiclePageCount);
      return productByQuery.data;
    } catch (err) {
      return err;
    }
  },
};

export const {
  _getNewProduct,
  _getSuggestProduct,
  _getProductById,
  _getProductByVin,
  _getProductByQuery,
  _getProductsByUpdate,
} = requestUtils;
export default requestUtils;
