import React from 'react'

const Tips = () => {
  return (
    <div>
      
    </div>
  )
}

export default Tips
