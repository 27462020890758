const PA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;



export const pictureSrcUpdate =(items) => {  
    if(!items?.folder_name && !items?.imgUrls[0]?.startsWith("public/")){
      return `${items?.imgUrls[0]}`
    }else{
     return `${items.imgUrls[0]?.startsWith("public/") ? PA : PA + "/"}${items?.folder_name ? `${items.imgUrls[0]?.startsWith("public/")?"":items?.folder_name}`: ""}/${items?.imgUrls[0]}`
    }
    
  }


  export const pictureSrcForProduct = (singleProductData,item) => {
     
    if(!singleProductData?.folder_name &&  !item.startsWith("public/")){
       return `${item}`
    }else{
       return  `${singleProductData?.folder_name ? PA + `${item?.startsWith("public/") ? "" : `/${singleProductData?.folder_name}`}/` : `${PA}/`}${item}`
    }
       
  }