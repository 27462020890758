import React, { useEffect, useState } from 'react';
import { IoIosClose, IoIosCheckmarkCircle } from 'react-icons/io';
import './Paymodal.css'; // Import a separate CSS file
import { FaCheckCircle } from "react-icons/fa";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PaymentForm from '../payment/PaymentForm';
const PaymentModal = ({ closeModal, carId }) => {
  const [clientToken, setClientToken] = useState(null);
  const [paypalOpen, setPaypalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/checkout/check/token`, {
        method: "POST",
      });
      const { client_token } = await response.json();
      setClientToken(client_token);
    })();
  }, []);

  const handlePaypalOpen = () => {
    setPaypalOpen(true);
  }

  const initialOptions = {
    "client-id": process.env.REACT_APP_CLIENT_API_PAYPAL,
    "enable-funding": "",
    "disable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_ac",
    dataClientToken: clientToken,
    components: "hosted-fields,buttons",
  };
  return (
    <div className="payment-modal-container z-50">
      {(paypalOpen && clientToken) ? <PayPalScriptProvider options={initialOptions}>
        <PaymentForm setPaypal={setPaypalOpen} />
      </PayPalScriptProvider> : <div className="payment-modal">
        <h2 className="text-lg font-semibold mb-2">Payment Details</h2>
        <IoIosClose className="close-icon" onClick={closeModal} />
        <div className="service-description bg-green-100 p-4 mb-4">
          <h3 className="text-lg font-semibold mb-2">Our Service Offer:</h3>
          <div className='grid grid-cols-2 gap-y-2 p-3'>
            <div className='flex items-center gap-2'>
              <FaCheckCircle size="20" color="green" /> <span>Hide Vehicle from Google </span>
            </div>
            <div className='flex items-center gap-2'>
              <FaCheckCircle size="20" color="green" /> <span>Hide Images from Google </span>
            </div>
            <div className='flex items-center gap-2'>
              <FaCheckCircle size="20" color="green" /> <span>Hide Vehicle from Auchistory</span>
            </div>
            <div className='flex items-center gap-2'>
              <FaCheckCircle size="20" color="green" /> <span>Hide Images from Auchistory</span>
            </div>
          </div>
          <p className='font-bold'>Total Amount: $49.99</p>
          <button
            onClick={handlePaypalOpen}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Pay Now
          </button>
        </div>
      </div>}
    </div>
  );
};

export default PaymentModal;