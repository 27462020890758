import React, {   useState } from 'react';
import useCarContext from './useCarContext';

const MyContextProvider = ({ children }) => {
  const [carId, setCarId] = useState('');

  const updateData = newData => {
    setCarId(newData);
  };

  const contextValue = {
    carId,
    updateData,
  };

  return <useCarContext.Provider value={contextValue}>{children}</useCarContext.Provider>;
};

export default MyContextProvider;