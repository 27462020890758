import { Route, Routes ,Navigate} from 'react-router-dom';
import './App.css';
import MainLayout from './layout/MainLayout';
import Business from './pages/about/Business';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import NotFoundPage from './pages/notfound/NotFoundPage';
import ProductCar from './pages/product/ProductCar';
import Productlist from './pages/productlist/Productlist';
import Privacy from './pages/terms/Privacy';
import Terms from './pages/terms/Terms';


//BLOG routes
import Records from './pages/blog/history/recordsBlog/Records';
import VehicleHistoryContent from './pages/blog/history/recordsBlog/VehicleHistoryContent';
import VehicleHistoryGuide from './pages/blog/history/recordsBlog/VehicleHistoryGuide';
import Evolution from './pages/blog/history/Evolution';
import Fraud from './pages/blog/history/Fraud';
import Vintage from './pages/blog/history/Vintage';

import Online from './pages/blog/sell/Online';
import Strategies from './pages/blog/sell/Strategies';
import Tips from './pages/blog/sell/Tips';
import Valuation from './pages/blog/sell/Valuation';
import Interpreting from './pages/blog/vehicle/Interpreting';
import Services from './pages/blog/vehicle/Services';
import Structure from './pages/blog/vehicle/Structure';
import Tools from './pages/blog/vehicle/Tools';
import VehicleReports from './pages/blog/history/recordsBlog/VehicleReports';
import VehiclePurchases from './pages/blog/history/recordsBlog/VehiclePurchases';
import VehicleHistoryAuthenticity from './pages/blog/history/recordsBlog/VehicleHistoryAuthenticity';




function App() {
  return (
    <div className="App font-[Roboto] font-extralight">
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path='/product/vehicle/:lot/:tag' element={<ProductCar />} />
          <Route path='/products/vehicle' element={<Productlist />} />
          <Route path='/check/terms' element={<Terms />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/check/privacy' element={<Privacy />} />
          <Route path='/about/business' element={<Business />} />

          {/* car history blog routes */}
          <Route path='/car-history/vehicle-history-records/why-vehicle-history-records-essential' element={<Records />} />
          <Route path ='/blog/car-history/vehicle-history-records/identifying-red-flags-vehicle-history-records' element={<VehicleHistoryContent />} />
          <Route path="/blog/car-history/vehicle-history-records/guide-obtaining-understanding-vehicle-history-reports" element={<VehicleHistoryGuide />} />
          <Route path="/blog/car-history/vehicle-history-records/components-detailed-vehicle-history-report/" element={<VehicleReports />} />
          <Route path='/blog/car-history/vehicle-history-records/vehicle-history-records-smart-car-purchases' element={<VehiclePurchases />} />
          <Route path='/blog/car-history/vehicle-history-records/ensuring-authenticity-vehicle-history-reports/' element={<VehicleHistoryAuthenticity />} />
          <Route path="/car-history/info/blog/identifying-red-flags-vehicle-history-records" element={<Navigate to="/blog/car-history/vehicle-history-records/identifying-red-flags-vehicle-history-records" replace />} />
       

          <Route path='/cat/history/evolution' element={<Evolution />} />
          <Route path='/cat/history/vintage' element={<Vintage />} />
          <Route path='/cat/history/fraud' element={<Fraud />} />

          <Route path='/cat/sell/tips' element={<Tips/>} />
          <Route path='/cat/sell/online' element={<Online />} />
          <Route path='/cat/sell/strategies' element={<Strategies />} />
          <Route path='/cat/sell/valuation' element={<Valuation />} />

          <Route path='/cat/vehicle/services' element={<Services />} />
          <Route path='/cat/vehicle/Structure' element={<Structure/>} /> 
          <Route path='/cat/vehicle/Tools' element={<Tools />} />
          <Route path='/cat/vehicle/Interpreting' element={<Interpreting />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
