import { Button, Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { _getProductByVin } from "../../utils/requestUtils";
import { FaCar } from "react-icons/fa6";
import { MdDoneOutline } from "react-icons/md";
import { RiSearchLine } from 'react-icons/ri';
import { IoCarSport } from "react-icons/io5";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
const CustomPictureSlider = () => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCheck, setSearchCheck] = useState("");
  const [searchCheckResult, setSearchCheckResult] = useState("");
  const images = [
    "https://vinhistory.s3.amazonaws.com/public/admin/web1-1.jpg",
    "https://vinhistory.s3.amazonaws.com/public/admin/web1-2.jpg",
    "https://vinhistory.s3.amazonaws.com/public/admin/web1-3.jpg",
  ];

  const handleChangeQuery = (e) => {
    const value = e.target.value;

    setSearchQuery(value);
  };
  const handleCheckVinByNavigate = async () => {
    if (searchCheck) {
      try {
        await _getProductByVin(searchCheck).then((data) =>
          setSearchCheckResult(data)
        );
      } catch (err) { }
    }
  };

  useEffect(() => {
    let formattedUrl = searchCheckResult?.name?.replace(/ /g, '-');
    if (searchCheckResult?.vin) {
      return navigate(`/product/vehicle/${searchCheckResult?.lot}/${searchCheckResult?.tag ? searchCheckResult?.tag?.replace(/ /g, '-') : formattedUrl}`);
    }
  }, [searchCheckResult]);
  useEffect(() => {
    const updateCurrentImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const intervalId = setInterval(updateCurrentImage, 10000);

    return () => clearInterval(intervalId);
  }, []);


  const handleSearchProduct = () => {
    if (searchQuery !== "") {
      navigate("/products/vehicle?searchVehicle=" + searchQuery);
    } else {
      navigate("/products/vehicle")
    }

  }
  return (
    <div className=" h-[500px] overflow-hidden relative">

      {images.map((image, index) => (
        <img
        key={index}
        src={image}
        alt={`slider ${index + 1}`}
        className={`h-full w-full object-cover ${index === currentImageIndex ? "block" : "hidden"
            }`}
            />
      ))}
      <div
        className={`absolute top-0 left-0 w-full h-full ${images.length > 1 ? "block" : "hidden"
          }`}
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
          ></div>


      <div
        className={
          "absolute left-[2%] top-[20%] md:left-[15%] w-[350px] h-[350px] rounded-[8px] md:w-1/3 bg-[#0d1113] md:h-[370px]    flex flex-col  p-2 text-white "
        }
      >
        

        <div className="w-full bg-[#0d1111] h-full new-search-psa  flex flex-col md:gap-y-5 rounded-[4px]  justify-center items-center shadow-md ">
       

          <div className="flex  items-center w-full justify-center">
            <div className="relative w-1/2">
              <input
                name="vinsearch"
                label="Vin Search"
                onChange={(e) => setSearchCheck(e.target.value)}
                className="w-full p-2 rounded-md bg-[#0000006f] border-b border-gray-500 focus:outline-none text-white pl-10"
                placeholder="Search By Vin or Lot..."
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <RiSearchLine className="text-gray-500" />
              </div>
            </div>

            <Button
              className="whitespace-nowrap rounded-md  text-white bg-green-900"
              onClick={handleCheckVinByNavigate}
            >
              Check
            </Button>
          </div>

          <div className="flex w-full items-center gap-2">
            <div className="flex-grow w-10 border-t border-b border-l border-green-500"></div> {/* Left side */}
            <span className="px-2">OR</span> {/* Centered text */}
            <div className="flex-grow w-10 border-t border-b border-r border-green-500"></div> {/* Right side */}
          </div>
          <div className="flex w-full justify-between items-center">

            <div className="flex items-center gap-2">
              <IoCheckmarkDoneCircle className="text-green-500" />
              <span className="text-green-400 bold">COPART</span>
              <IoCheckmarkDoneCircle className="text-green-500" />
              <span className="text-green-400 bold">IAAI</span>
            </div>
            <IoCarSport size="40" className=" w-[20%]   text-green-900 border-green-900 border-b-2 mr-2" />
          </div>
          {/* ... (Other elements remain unchanged) */}
          <div className="flex flex-col w-full md:flex-row gap-5 md:gap-10 md:items-center">
            <label className="form-cols md:w-[50%]">
              <select
                name="make"
                id="make"
                className="mt-1 p-2 w-full text-white border-b bg-gray-900 border-gray-700 focus:outline-none focus:ring focus:border-gray-900"
              >
                <option value="">All makes</option>
                {/* Add other make options */}
              </select>
            </label>
            <label className="form-cols md:w-[50%]">
              <select
                name="model"
                id="model"
                className="mt-1 p-2 w-full text-white border-b bg-gray-900 border-gray-700 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">All models</option>
                {/* Add other model options */}
              </select>
            </label>
          </div>

          {/* ... (Other form elements remain unchanged) */}

          <div className="footer-buttons" style={{ width: '100%' }}>
            <div className=" flex w-full   p-3">
              <button
                id="show-btn"
                className="rounded-md bg-green-900 p-3 text-white"
                onClick={() => navigate("/products/vehicle")}
              >
                Show 121 289 vehicles
              </button>
            </div>
          </div>

        </div>

        {searchCheckResult?.response?.status === 403 && (
          <h3 className="text-[#d43a42] p-5 text-base">Product Not Found !</h3>
        )}


      </div>
    
    </div>
  );
};

export default CustomPictureSlider;
