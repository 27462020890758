import { Button } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineHistory, AiOutlineSearch } from "react-icons/ai";
import { BsClipboardDataFill } from "react-icons/bs";
import { PiArchiveDuotone } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import ProductListskeleton from "../../components/skeleton/ProductListskeleton";
import {
  _getNewProduct,
  _getProductByQuery,
  _getProductsByUpdate,
} from "../../utils/requestUtils";
import { pictureSrcUpdate } from "../../utils/imgsrc-utils";

const Productlist = () => {
  const PA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const queryValue = queryString.get("searchVehicle");
  const [vehicleProducts, setVehicleProducts] = useState([]);
  const [vehiclePageCount, setVehiclePageCount] = useState(1);
  const [vehicleProductsLoading, setVehicleProductsLoading] = useState(true);
  const [vehiclePageLoading, setVehiclePageLoading] = useState(false);
  const [vehiclePagelength, setVehiclePagelength] = useState(null);
  const [datalength, setDataLength] = useState(null);
  const [similarData, setSimilarData] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const handleLoadMore = () => {
    setVehiclePageCount((prev) => prev + 1);
    setVehiclePageLoading(true);
  };

   
  
 
  useEffect(() => {
    try {
      if (queryValue) {
        _getProductByQuery(queryValue, vehiclePageCount).then((data) => {
          setVehicleProductsLoading(false);
          const idsSet = new Set(vehicleProducts?.map((item) => item?.id));

          // Filter out elements from the fetched data that have duplicate ids
          const uniqueData = data?.data?.filter((item) => !idsSet.has(item?.id));

          setVehicleProducts([...vehicleProducts, ...uniqueData]);
          setVehiclePageLoading(false);
          setDataLength(data?.totalLength);
          setVehiclePagelength(data?.totalPages);
        });
      } else {
        _getProductsByUpdate(vehiclePageCount).then((data) => {
          setVehicleProductsLoading(false);
          const idsSet = new Set(vehicleProducts?.map((item) => item?.id));

          // Filter out elements from the fetched data that have duplicate ids
          const uniqueData = data?.data?.filter((item) => !idsSet.has(item?.id));
          setVehicleProducts([...vehicleProducts, ...uniqueData]);
          setVehiclePageLoading(false);
          setDataLength(data?.totalLength);
          setVehiclePagelength(data?.totalPages);
        });
      }
    } catch (err) {}
  }, [queryValue, vehiclePageCount, vehicleProductsLoading]);

 useEffect(() => {
    try {
      _getNewProduct().then((data) => setSimilarData(data));
    } catch (err) {}
  }, []);

  return (
    <div className="bg-[#d1d5db] overflow-hidden">
    <div className="flex flex-col pt-20 md:flex-row justify-center gap-3 p-5 ">
      <div className="flex flex-col gap-2">
      <div className="sidebox  md:w-[80%] p-1 h-[140px] bg-[#0c1a20] flex flex-col rounded-[5px] justify-center items-center">
        <h1 className="text-[#dddbdb] flex items-center gap-1">
          <PiArchiveDuotone /> SEARCH CATEGORY
        </h1>
        <span className="bg-[#2e3dc4]  text-white rounded-[4px]  p-1  w-[90px] flex items-center gap-1">
          <AiOutlineHistory />
          Archived
        </span>
       

      </div> 
      <div className=" pt-10 hidden md:flex md:flex-col items-center">
            <h1 className="p-3 text-[20px]">Suggest For You</h1>

            <div className="grid grid-cols-1  place-content-center gap-x-10 gap-y-10 w-[20vw]    ">
              {similarData?.map((item, index) => (
                <div
                  key={item?.id}
                  className="newCars-Container items-center  flex flex-col gap-2"
                >
                  {/* <CustomSlider item={item} /> */}
                  <div className="rounded-[6px] sm:rounded-xl w-[130px] h-[100px] sm:w-[200px] sm:h-[150px] overflow-hidden relative">
                  <div
                    className="flex transition-all h-full duration-500"

                  >

                    <img

                      src={pictureSrcUpdate(item)}
                      alt={`${item.vin}_${item.name}_slider_1`}
                      className=""
                    />

                  </div>
                </div>

                  <p className="whitespace-nowrap text-elipsis  w-1/2 overflow-hidden text-[13px]">
                    {item?.name}
                  </p>
                  <p className="whitespace-nowrap">
                    ${" "}
                    {item?.final_bid_formatted === "0" || item?.final_bid_formatted === "---"  || !item?.final_bid_formatted
                      ? "No Information"
                      : item?.final_bid_formatted}
                  </p>
                  <Button
                    onClick={() => window.open(`/product/vehicle/${item?.lot}/${item?.tag ?  item?.tag?.replace(/ /g, '-') : item?.name?.replace(/ /g,'-') }`)}
                  >
                    View More
                  </Button>
                </div>
              ))}
            </div>
          </div>
</div>
      <div>
        <div className="text-[#c4c4c4] bg-[#263238]  w-xs md:w-[896px] text-[16px] flex justify-between  rounded-[4px] border-b p-3">
          <p className="pt-3 flex items-center gap-1">
            <AiOutlineSearch /> Search Result{" "}
            {queryValue ? queryValue?.toUpperCase() : ""}
          </p>
          <p className="pt-3 flex  gap-1">
            {" "}
            <BsClipboardDataFill /> Total Result:{datalength}
          </p>
        </div>

          
        {!vehicleProductsLoading && (
          <>
            {" "}
            {vehicleProducts?.map((items) => (
              <Fragment key={items.id}>
                <div className="productCard bg-[#263238] rounded-[3px] w-[100%]">
                  <div className="flex flex-col justify-center">
                    <div className="relative flex flex-col p-2 md:flex-row border-b border-gray-500 md:space-x-5 space-y-3 md:space-y-0   max-w-xs md:max-w-4xl md:h-[200px] mx-auto   ">
                      <div className="w-full md:w-[250px] h-full  ">
                        <img
                          src={pictureSrcUpdate(items) }
                          alt="slider Car "
                          className="rounded-[10px] w-full  h-[170px]"
                        />
                      </div>
                      <div className="  flex flex-col md:flex-row items-start gap-5   ">
                        <div className="flex flex-col items-start  md:border-r border-gray-500 w-[25em] p-3 h-[170px] gap-2">
                          <h1 className="  md:text-[18px] text-[12px] text-white">
                            {items?.name}
                          </h1>
                          <div className="md:text-[14px] text-gray-500 text-base flex flex-col md:flex-row">
                            <div className="flex flex-col items-start">
                              <p>
                                <span>Vin:</span>
                                <span className="text-white">{items?.vin}</span>
                              </p>

                              <p>
                                <span>Primary:</span>
                                <span className="text-white">
                                  {items?.primary_damage}
                                </span>
                              </p>
                              <p>
                                <span>Start code:</span>
                                <span className="text-white">
                                {items?.start_code}
                                </span>
                              </p>
                              <p classname="text-white whitespace-nowrap">
                                <span>Odometer:</span>
                                <span className="text-white whitespace-nowrap">
                                {items?.odometer_to_string ? items?.odometer_to_string + (items?.odometer_to_string?.includes("mi") ? '' : ' (mi)' ): items?.odometer + "(mi)"}
                                </span>
                              </p>
                            </div>
                            
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-3 md:w-[200px]">
                          <p>
                            {items?.final_bid_formatted === "0" || items?.final_bid_formatted === "---"  || !items?.final_bid_formatted? (
                              <>
                                <span className="font-normal text-white text-[13px] ">
                                  No Informations
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="text-gray-500">
                                  Final Price:
                                </span>
                                <span className="font-normal text-white ">
                                  ${items?.final_bid_formatted}
                                </span>
                              </>
                            )}
                          </p>
                          <Button
                            onClick={() =>
                            window.open(`/product/vehicle/${items?.lot}/${items?.tag ? items?.tag?.replace(/ /g, '-') : items?.name?.replace(/ /g, '-')}`)
                            }
                            className="bg-[#151e22b9]"
                          >
                            View More
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
            {vehicleProducts?.length!==0 && vehiclePageCount !== vehiclePagelength && !vehiclePageLoading && (
              <div
                className="flex  items-center justify-center w-full p-5"
                onClick={handleLoadMore}
              >
                <Button>Load More</Button>
              </div>
            )}
            {vehiclePageLoading && (
              <div className="flex bg-[#263238] flex-col items-center justify-center w-full">
                <div className="flex flex-col items-center">
                 {Array.from({length:10}).map((_,index) => (
                  <div key={index}>
                  <ProductListskeleton/>
                  </div>
                ))}
                </div>
              </div>
            )}
          </>
        )}
        {vehicleProductsLoading && (
          <div className="flex bg-[#263238] flex-col items-center justify-center w-[100%] ">
             {Array.from({length:10}).map((_,index) => (
                <div key={index} className="flex flex-col items-center">
                  <ProductListskeleton/>
                  </div>
                ))}
          </div>
        )}
        
      
    
      </div>


      
        
    </div>
    <div className="p-10 pt-5 w-full flex flex-col items-center">
            <h1 className="text-[22px] p-3">Similar Products</h1>

            <div className="grid grid-cols-2 md:grid-cols-2 2xl:grid-cols-5  place-content-center gap-x-20 gap-y-15 w-[80vw]    ">
              {similarData?.map((item, index) => (
                <div
                  key={item?.id}
                  className="newCars-Container items-center  p-1 flex flex-col gap-2"
                >
                  {/* <CustomSlider item={item} /> */}

                  <div className="rounded-[6px] sm:rounded-xl w-[130px] h-[100px] sm:w-[200px] sm:h-[150px] overflow-hidden relative">
                  <div
                    className="flex transition-all h-full duration-500"

                  >

                    <img

                      src={pictureSrcUpdate(item) }
                      alt={`${item.vin}_${item.name}_slider_1`}
                      className=""
                    />

                  </div>
                </div>

                  <p className="whitespace-nowrap w-[90%] text-elipsis overflow-hidden text-[14px]">
                    {item?.name}
                  </p>
                  <p className="whitespace-nowrap">
                    ${" "}
                    {item?.final_bid_formatted === "0" || item?.final_bid_formatted === "---"  || !item?.final_bid_formatted 
                      ? "No Information"
                      : "$ "  + item?.final_bid_formatted}
                  </p>
                  <Button
                    onClick={() => window.open(`/product/vehicle/${item?.lot}/${item?.tag ? item?.tag?.replace(/ /g, '-') : item?.name?.replace(/ /g, ('-'))}`)}
                  >
                    View More
                  </Button>
                </div>
              ))}
            </div>
          </div>
    </div>
  );
};

export default Productlist;
