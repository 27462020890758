import React from 'react';
import { Link } from 'react-router-dom'; // If you're using React Router

const NotFoundPage = () => {
  return (
    <div className="flex flex-col w-full items-center justify-center h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-gray-800 mb-4">404 Not Found</h1>
      <p className="text-lg text-gray-600 mb-8">Sorry, the page you are looking for might be in another castle.</p>
      <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
        Back Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
