import React from 'react'

const VehicleReports = () => {
    return (
        <div className="flex justify-center">
        <div className="max-w-2xl p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-4">Exploring the Components of a Detailed Vehicle History Report</h1>
            <p className="mb-4">As a car buyer, you should check the car history report, whether the car has been in accidents (e.g. cleaned or rebuilt), how many accidents it has been in, maintenance history, exact mileage, reviews, history, and if it has any bank obligations. These reports protect buyers from surprises and ensure they are safe and sound after the transaction.</p>

            <p className="mb-4">A car's history can be simple or a little complicated, depending on how well you know it. Some details are as simple as whether the car has been in an accident or if the title is clean. But other content may be technical notes or details that may not be easy to understand if you're new to cars. However, these reports are designed to be simple to read and you can see instructions on what to do. </p>

            <p className="mb-4">This article will teach you how to check the various components of a detailed vehicle history report.</p>

            <h2 className="text-xl font-bold mb-2">Key Components in VHR</h2>
            <p className="mb-4">Vehicle history records can be several or several pages long. They include different sections that tell you about the car's past, such as what accidents it has been in, who previously owned it, whether it had any problems, and other important details. Therefore, the length depends on the amount of information about the car.</p>

            <p className="mb-4">Let’s explore the main components of the vehicle history report. The main parts of a vehicle history report are:</p>

            <ul className="list-disc pl-6 mb-4">
                <li>Accident History: Info about any accidents the car was in.</li>
                <li>Title History: Details about the car's legal status, like if it was salvaged or had flood damage.</li>
                <li>Ownership History: How many people owned the car before and for how long.</li>
                <li>Service and Maintenance Records: Records of repairs and maintenance done on the car.</li>
                <li>Odometer Readings: Info about how many miles the car has been driven.</li>
                <li>Recalls and Lemon Status: Any recalls for the car and if it's ever been labeled as a lemon.</li>
                <li>Theft History: Whether the car was ever stolen.</li>
                <li>Liens and Financial Issues: If there's any money owed on the car or if it has any financial problems.</li>
            </ul>

            <h2 className="text-xl font-bold mb-2">Car Accident History</h2>
            <p className="mb-4">The accident history in a vehicle history report includes information about any accidents the car has been involved in. This can include details such as the date of the accident, the severity of the damage, and whether the car was subsequently repaired.</p>

            <p className="mb-4">For example, imagine a Mitsubishi Mirage was in an accident with moderate damage. A report might say something like:</p>

            <p className="mb-4">"Accident reported on [date]: Moderate damage to the front left side of the vehicle. Car was repaired afterward."</p>

            <p className="mb-4">This will tell you what part of the car was damaged and what part of the car was damaged and later repaired on a specific date. Knowing the accident history can help buyers understand the condition of the car and whether there may be any lingering issues from past accidents.</p>

            <h2 className="text-xl font-bold mb-2">Title History</h2>
            <p className="mb-4">Title history in a vehicle history report provides details about the legal status of the car's title, which indicates who owns the vehicle and if there are any issues with it.</p>

            <p className="mb-4">For example, a title history entry might say:</p>

            <p className="mb-4">"Title status: Clean"</p>

            <p className="mb-4">This means that the car's title is clean, indicating there are no major issues like salvage or flood damage.</p>

            <h2 className="text-xl font-bold mb-2">Ownership History</h2>
            <p className="mb-4">Ownership history in a vehicle history report shows how many people owned the car before and for how long. It typically includes the number of previous owners and the length of time each owner had the vehicle.</p>

            <p className="mb-4">For example, an ownership history entry might say:</p>

            <p className="mb-4">"Number of owners: 2"</p>

            <p className="mb-4">This means that the car has had two previous owners before the current owner.</p>

            <h2 className="text-xl font-bold mb-2">Service and Maintenance Records</h2>
            <p className="mb-4">Service and maintenance records are very important. They speak about what kind of repairs car has experienced. All maintenance reports can be found in such documents. Everything from oil changes to tire rotations is there. These reports also present non-routine maintenance items such as brake replacements, as well as major repairs.</p>

            <p className="mb-4">For example, a service and maintenance record might include:</p>

            <p className="mb-4">Date: 01/15/2022<br />
                Service: Oil Change<br />
                Mileage: 20,000 miles<br />
                Service Provider: XYZ Auto Shop</p>

            <p className="mb-4">This entry tells you that on January 15, 2022, the car had an oil change done at 20,000 miles, and it was serviced by XYZ Auto Shop.</p>

            <h2 className="text-xl font-bold mb-2">Odometer Readings</h2>
            <p className="mb-4">The odometer readings section in a vehicle history report shows how many miles the car went through. It is updated from time to time. These sections include a record of the mileage at different points in the car's history.</p>

            <p className="mb-4">For example, the odometer readings section might include:</p>

            <p className="mb-4">Date: 01/01/2020<br />
                Mileage: 10,000 miles</p>

            <p className="mb-4">This entry tells you that on January 1, 2020, the car had 10,000 miles on the odometer.</p>

            <h2 className="text-xl font-bold mb-2">Recalls and Lemons</h2>
            <p className="mb-4">The "Recalls and Lemon Status" section of the vehicle history report provides information about any recalls for the car and whether the car has ever been classified as a lemon.</p>

            <p className="mb-4">Recalls: Recalls are issued by automakers. The producers of the car usually warn drivers about safety-related defects. During manufacturing some things can go wrong and carmakers need to report them. Any issue that affects vehicle performance can be found in recall reports. In addition to this, recalls can be fixed for free at service centers.  </p>

            <p className="mb-4">Lemon Status: lemon status is an indicator that even though the car was fixed multiple times the issue is still there. Such cars are bought very rarely. But in the United States, car owners that have a lemon status can ask for a money refund or replacement of the car. </p>

            <p className="mb-4">For example, a recalls and lemon status section might include:</p>

            <p className="mb-4">Recall: "Airbag recall issued by manufacturer in 2020. Repaired by authorized dealer."<br />
                Lemon Status: "No lemon history reported."</p>

            <p className="mb-4">This indicates that there was an airbag recall for the car in 2020, but it was repaired by an authorized dealer. Additionally, there is no history of the car being classified as a lemon.</p>

            <h2 className="text-xl font-bold mb-2">Liens and Financial Issues</h2>
            <p className="mb-4">The "Liens and Financial Issues" section in a vehicle history report provides information about any outstanding financial obligations or liens associated with the car.</p>

            <p className="mb-4">Liens: A lien is a legal claim against the car by a creditor or lender who has provided financing for the vehicle. It means that the owner of the car still owes money to the lender, and the lender has the right to repossess the car if the owner fails to make payments.</p>

            <p className="mb-4">For example, if someone buys a car using a loan from a bank, the bank may have a lien on the car until the loan is paid off.</p>

            <p className="mb-4">Financial Issues: This part of the report may also include other financial concerns related to the car, such as unpaid taxes or fees.</p>

            <p className="mb-4">An example entry in the Liens and Financial Issues section might be:</p>

            <p className="mb-4">"Outstanding lien: $5,000 owed to ABC Bank for auto loan."</p>

            <p className="mb-4">This indicates that there is still money owed on the car to ABC Bank, and until that loan is paid off, the bank has a legal claim to the vehicle.</p>
        </div>
        </div>
    )
}

export default VehicleReports
