import React from 'react'
import { Outlet } from 'react-router-dom'
import Topbar from '../components/topbar/Topbar'
import Footer from '../components/footer/Footer'

const MainLayout = () => {
  return (
    <div>
        <Topbar/>
       <Outlet/>
       <Footer/>
    </div>
  )
}

export default MainLayout
