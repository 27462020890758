import React from 'react'

const Business = () => {
  const PA = "https://allvinhistory-bucket.s3.amazonaws.com/assets/admin"
  return (
    <div className='flex   items-center justify-center w-full font-[SairaCondensed] '>
      <div className=" max-h-[1200px] min-h-[900px]  overflow-hidden relative">
        <img
          src={PA + "/business.jpg"}
          alt="Contact content no.1"
          className={`h-full w-full object-cover`}
        />
        <div
          className={`absolute top-0 left-0 w-full h-full `}
          style={{ background: "rgba(0, 0, 0, 0.8)" }}
        ></div>
        <div className={`absolute top-[10%]  w-full `}>
          <div className=" w-full flex flex-col gap-20  md:text-xl">
            <div className="left-0 text-left w-full text-white">
              <p className="md:w-[40%]">
                CheckedVin is a unique opportunity for people who are buying or selling their vehicles online.
                We provide free access to VIN codes and damage reports on various cars.
                Whether it is an odometer reading, images of past accidents, repair records, or anything else, CheckedVin is ready to provide all info to our clients.
              </p>
            </div>
            <div className=" right-0   w-full text-white">
              <p className='md:w-[50%] md:float-right md:pr-20   '>
                We are proud to state that CheckedVin has existed for many years on the internet and has served tens of thousands of buyers and sellers with 100% trust score.
                We are considered to be one of the most reliable service providers on the market and we commit to the service excellence.
                All information provided on our website is completely free of any charge. However, may you require additional data you can contact our support service.
              </p>
            </div>
            <div className="w-full text-white">
              <p className='md:w-[40%]  text-center'>
                CheckedVin customer care team works for 24 hours a week and provides assistance on any matter required.
                Leave us your contact information or send us an email explaining your query.
                 We will do our best to get back to you in no more than 48 hours.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Business
