import React, { useEffect, useRef, useState } from "react";
import { BiX, BiMenu, BiSolidHelpCircle } from "react-icons/bi"; // Import the required icons
import { SiAboutdotme } from "react-icons/si";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { AiFillCloseCircle, AiFillHome } from "react-icons/ai";
import { HiMiniHomeModern } from "react-icons/hi2";
import { FcAbout } from "react-icons/fc";
import { __useClickOutside } from "../_useOutsideref/_useOutsideClick";
import { FaBriefcase, FaMailBulk, FaTelegram } from "react-icons/fa";

const Topbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const [isCarHistoryOpen, setIsCarHistoryOpen] = useState(false);
  const [isSellTipsOpen, setIsSellTipsOpen] = useState(false);
  const [isVinInfoOpen, setIsVinInfoOpen] = useState(false);




  const outsideRef = useRef();
  const outsideMenuRef = useRef();
  const PA = "https://vinhistory.s3.amazonaws.com/public/admin"
  const handleHover = () => {
    setIsDropdownOpen(true);

  };
  const handleLeave = () => {
    setIsDropdownOpen(false);
  };


  
  const toggleMenu = () => {
    setShowMenu(true);
  };
  __useClickOutside(outsideRef, () => setIsDropdownOpen(false));
  __useClickOutside(outsideMenuRef, () => setShowMenu(false));
  useEffect(() => {
    // Function to check window dimensions and close the menu if it's open and window size is too big
    const handleWindowResize = () => {
      if (window.innerWidth >= 640) {
        setShowMenu(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      {/* Mobile View */}
      <div className="md:hidden w-full fixed z-[53] bg-[#263238]  h-[70px]">
        <button
          className="fixed top-4 left-2  text-white p-2 bg-gray-800 rounded-full focus:outline-none"
          onClick={toggleMenu}
        >
          {showMenu ? (
            <BiX className="h-6 w-6" />
          ) : (
            <BiMenu className="h-6 w-6" />
          )}
        </button>
      </div>

      {/* Desktop View */}
      <div className="hidden static top-0 bg-[#0f5156] text-white md:block border-b-[2px] border-[#182024] ">
        {/* Your default menu content for desktop view */}
        <div className="flex items-center w-full h-8 bg-[#263238]">
          <div className="w-full flex-end ">
            <span className="flex items-center gap-1 pr-3 float-right text-blue-200"><FaTelegram size="20" />Telegram:
              <a href="https://t.me/checkedvininfo" target="_blank" rel="noopener noreferrer" >@checkedvin.com</a></span>
          </div>
        </div>

        <div className="flex items-center w-full h-[60px] bg-bluegray-700">
          <Link to="/">
            <span className="flex gap-2 items-center pl-3">
              <p className="ml-2 text-xl font-bold tracking-wide text-[#f7f7f7] uppercase">CheckedVin</p>
              <img className="w-[60px] h-[60px]" src={PA + '/checkedvinweb1.png'} alt="" />
            </span>
          </Link>
          <div className="topbar-desk-menu  m-auto flex list-none gap-5   ">
            <Link to="/">
              <li className="py-2 flex hover:text-gray-400  items-center gap-2 cursor-pointer">
                <HiMiniHomeModern
                  className="text-green-200"
                  style={{ width: "20px", height: "20px" }}

                />
                Main
              </li>
            </Link>
            <Link to="/contact">
              <li className="py-2 flex items-center gap-2 cursor-pointer">
                <FaLocationDot
                  style={{ width: "20px", height: "20px" }}
                  color="red"
                />
                Contact
              </li>
            </Link>
            <li
              className="text-center px-4 py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
              onMouseEnter={() => setIsAboutUsOpen(true)}
              onMouseLeave={() => setIsAboutUsOpen(false)}
            >
              <div className="flex items-center gap-2 ">
                <FcAbout className="" style={{ width: "23px", height: "23px" }} />
                About Us
              </div>

              <div
                className={`absolute  top-[100%] w-48 bg-[#263238] rounded-md z-50 ${isAboutUsOpen ? "block" : "hidden"
                  }`}
              >
                <ul className="p-4 space-y-2">
                  <Link to="/about/business">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      <FaBriefcase size="20" />  Business
                    </li>
                  </Link>
                </ul>
              </div>

            </li>


            <li
              className="text-center px-4 py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
              onMouseEnter={() => setIsCarHistoryOpen(true)}
              onMouseLeave={() => setIsCarHistoryOpen(false)}
              ref={outsideRef}
            >
              <div className="flex items-center gap-2 ">

                Car history
              </div>

              <div
                className={`absolute  top-[100%] w-48 bg-[#263238] rounded-md z-50 ${isCarHistoryOpen ? "block" : "hidden"
                  }`}
              >
                <ul className="p-4 space-y-2">
                  <Link to="/car-history/vehicle-history-records/why-vehicle-history-records-essential">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      Vehicle History Records
                    </li>
                  </Link>
                  <Link to="/cat/history/vintage">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      Vintage cars
                    </li>
                  </Link>
                  <Link to="/cat/history/evolution">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      VIN System Evolution
                    </li>
                  </Link>
                  <Link to="/cat/history/fraud">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      VIN Fraud
                    </li>
                  </Link>
                </ul>
              </div>

            </li>
            <li
              className="text-center px-4 py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
              onMouseEnter={() => setIsSellTipsOpen(true)}
              onMouseLeave={() => setIsSellTipsOpen(false)}
              ref={outsideRef}
            >
              <div className="flex items-center gap-2 ">

                VehiBuying and Selling Tips
              </div>

              <div
                className={`absolute  top-[100%] w-48 bg-[#263238] rounded-md z-50 ${isSellTipsOpen ? "block" : "hidden"
                  }`}
              >
                <ul className="p-4 space-y-2">
                  <Link to="/cat/sell/tips">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      Tips for Buying a Used Car
                    </li>
                  </Link>
                  <Link to="/cat/sell/online">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      How to Sell Your Car Online
                    </li>
                  </Link>
                  <Link to="/cat/sell/strategies">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      Negotiation Strategies
                    </li>
                  </Link>
                  <Link to="/cat/sell/valuation">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      VIN-based Valuation
                    </li>
                  </Link>

                </ul>
              </div>

            </li>


            <li
              className="text-center px-4 py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
              onMouseEnter={() => setIsVinInfoOpen(true)}
              onMouseLeave={() => setIsVinInfoOpen(false)}
              ref={outsideRef}
            >
              <div className="flex items-center gap-2 ">

                Vehicle Identification Number (VIN)
              </div>

              <div
                className={`absolute  top-[100%] w-48 bg-[#263238] rounded-md z-50 ${isVinInfoOpen ? "block" : "hidden"
                  }`}
              >
                <ul className="p-4 space-y-2">
                  <Link to="/cat/vehicle/structure">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      Structure of a VIN
                    </li>
                  </Link>
                  <Link to="/cat/vehicle/tools">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      VIN Decoding Tools
                    </li>
                  </Link>
                  <Link to="/cat/vehicle/services">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      VIN Check Services
                    </li>
                  </Link>
                  <Link to="/cat/vehicle/interpreting">
                    <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                      Interpreting VIN data
                    </li>
                  </Link>

                </ul>
              </div>
            </li>
          </div>
        </div>
      </div>

      {/* Off-canvas Menu */}
      {showMenu && (
        <div className="fixed top-0 left-0 w-full h-full bg-[#263238] bg-opacity-50 z-[54] overflow-hidden">
          <div ref={outsideMenuRef} className="bg-[#263238] w-[200px] sm:w-[30%] h-full p-4">
            <div className="flex items-center p-4  justify-between border-b-[1px]">
              <img className="w-[40px] h-[40px]" src={PA + '/checkedvinweb1-2.png'} alt="" />
              <AiFillCloseCircle className="text-white w-[25px] h-[25px]" onClick={() => setShowMenu(false)} />

            </div>
            {/* Your off-canvas menu content */}
            <ul className="text-white">
              <Link to="/">
                <li className="py-2 flex items-center gap-2">
                  <HiMiniHomeModern
                    className="text-green-200"
                    style={{ width: "20px", height: "20px" }}

                  />
                  Menu
                </li>
              </Link>
              <Link to="/contact">
                <li className="py-2 flex items-center gap-2">
                  <FaLocationDot
                    style={{ width: "20px", height: "20px" }}
                    color="red"
                  />
                  Contact
                </li>
              </Link>
              <li
                className="text-center px-4 py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
                onMouseEnter={() => setIsAboutUsOpen(true)}
                onMouseLeave={() => setIsAboutUsOpen(false)}
              >
                <div className="flex items-center gap-2 ">
                  <FcAbout className="" style={{ width: "23px", height: "23px" }} />
                  About Us
                </div>

                <div
                  className={`absolute  top-[100%] w-48 bg-[#263238] rounded-md z-50 ${isAboutUsOpen ? "block" : "hidden"
                    }`}
                >
                  <ul className="p-4 space-y-2">
                    <Link to="/about/business">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        <FaBriefcase size="20" />  Business
                      </li>
                    </Link>


                  </ul>
                </div>

              </li>
              <li
                className="text-center   py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
                 onMouseEnter={() => setIsCarHistoryOpen(true)}
                 onMouseLeave={() => setIsCarHistoryOpen(false)}
               
              >
                <div className="flex items-center gap-2 ">

                  Car history
                </div>

                <div
                  className={`absolute left-[100%] top-[20%] w-48 bg-[#30393d] rounded-md z-50 ${isCarHistoryOpen ? "block" : "hidden"
                    }`}
                >
                  <ul className="p-4 space-y-2">    
                    <Link to="/cat/history/records">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        Vehicle History Records
                      </li>
                    </Link>
                    <Link to="/cat/history/vintage">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        Vintage cars
                      </li>
                    </Link>
                    <Link to="/cat/history/evolution">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        VIN System Evolution
                      </li>
                    </Link>
                    <Link to="/cat/history/fraud">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        VIN Fraud
                      </li>
                    </Link>
                  </ul>
                </div>

              </li>
              <li
                className="text-center   py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
                onMouseEnter={() => setIsSellTipsOpen(true)}
                onMouseLeave={() => setIsSellTipsOpen(false)}
               
              >
                <div className="flex items-center gap-2 ">

                  VehiBuying and Selling Tips
                </div>

                <div
                  className={`absolute left-[100%] top-[20%] w-48 bg-[#30393d] rounded-md z-50 ${isSellTipsOpen ? "block" : "hidden"
                    }`}
                >
                  <ul className="p-4 space-y-2">
                    <Link to="/cat/sell/tips">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        Tips for Buying a Used Car
                      </li>
                    </Link>
                    <Link to="/cat/sell/online">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        How to Sell Your Car Online
                      </li>
                    </Link>
                    <Link to="/cat/sell/strategies">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        Negotiation Strategies
                      </li>
                    </Link>
                    <Link to="/cat/sell/valuation">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        VIN-based Valuation
                      </li>
                    </Link>

                  </ul>
                </div>

              </li>


              <li
                className="text-center   py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
                onMouseEnter={() => setIsVinInfoOpen(true)}
                onMouseLeave={() => setIsVinInfoOpen(false)}
               
              >
                <div className="flex items-center gap-2 ">

                 Vehicle Identification Number (VIN)
                </div>

                <div
                  className={`absolute left-[100%] top-[20%] w-48 bg-[#30393d] rounded-md z-50 ${isVinInfoOpen ? "block" : "hidden"
                    }`}
                >
                  <ul className="p-4 space-y-2">
                    <Link to="/cat/vehicle/structure">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        Structure of a VIN
                      </li>
                    </Link>
                    <Link to="/cat/vehicle/tools">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        VIN Decoding Tools
                      </li>
                    </Link>
                    <Link to="/cat/vehicle/services">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        VIN Check Services
                      </li>
                    </Link>
                    <Link to="/cat/vehicle/interpreting">
                      <li className="py-2  flex justify-center items-center gap-2 cursor-pointer">
                        Interpreting VIN data
                      </li>
                    </Link>

                  </ul>
                </div>

              </li>

              {/* Add more menu items as needed */}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Topbar;
